/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles } from '@material-ui/core';
import { COLORS } from '../../../plTheme';

interface RepProgressBarProps {
  value: number | null | undefined;
  type: string | undefined;
  maxValue: number | null | undefined;
  minValue: number | null | undefined;
}

const RepProgressBar: React.FC<RepProgressBarProps> = ({
  value,
  type,
  maxValue,
  minValue,
}: RepProgressBarProps) => {
  let barBackgroundColor = '';

  let maxVal =
    maxValue == 80
      ? 100
      : maxValue == 60.4
      ? 99
      : maxValue == 12.7
      ? 20.97
      : 49.68;
  let percentage = 0;
  if (value) {
    if (maxValue && maxValue != 0) {
      percentage = Math.round((value / maxValue) * 100);
    }
  }
  if (
    type === 'amount' ||
    type === 'dealSizeAmount' ||
    type === 'percentage' ||
    type === 'winRatePercentage' ||
    type === 'dealsClosed'
  ) {
    if (value! < minValue! + (maxValue! - minValue!) * 0.25) {
      barBackgroundColor = COLORS.AVATARS_RED_2; //red
    } else if (
      value! >= minValue! + (maxValue! - minValue!)! * 0.25 &&
      value! < minValue! + (maxValue! - minValue!)! * 0.75
    ) {
      barBackgroundColor = COLORS.TERTIARY_COLOR_ORANGE; //orange
    } else {
      barBackgroundColor = COLORS.SUCCESS_PRESSED; //green
    }
  } else if (type === 'days') {
    if (value! > minValue! + (maxValue! - minValue!)! * 0.75) {
      barBackgroundColor = COLORS.AVATARS_RED_2;
    } else if (
      value! >= minValue! + (maxValue! - minValue!)! * 0.25 &&
      value! < minValue! + (maxValue! - minValue!)! * 0.75
    ) {
      barBackgroundColor = COLORS.TERTIARY_COLOR_ORANGE;
    } else {
      barBackgroundColor = COLORS.SUCCESS_PRESSED;
    }
  } else if (type === 'salesActivity') {
    const percent = (value! / maxVal) * 100;
    if (percent < 35) barBackgroundColor = COLORS.AVATARS_RED_2;
    else if (percent >= 35 && percent < 70)
      barBackgroundColor = COLORS.TERTIARY_COLOR_ORANGE;
    else barBackgroundColor = COLORS.SUCCESS_PRESSED;
  }
  value =
    type === 'salesActivity' ? value : percentage > 100 ? 100 : percentage;
  // value = percentage > 100 ? 100 : percentage;

  const BorderLinearProgressBar = withStyles(() =>
    createStyles({
      root: {
        height: '5.79px',
        width: '150px',
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: 'rgba(143, 146, 161, 0.2)',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: `${barBackgroundColor}`,
      },
    }),
  )(LinearProgress);
  return (
    <BorderLinearProgressBar
      variant="determinate"
      value={
        value
          ? type == 'salesActivity'
            ? (value! / maxVal) * 100
            : value > 100
            ? value / 2
            : value
          : 0
      }
    />
  );
};

export default RepProgressBar;
